<template>
    <el-alert type="error" center description="不想显示公告请点清空，而不是删除编辑框中的内容再点保存" :closable="false" />
    <el-divider />
    <div style="width: 100%;">
        <el-alert type="warning" center description="弹窗公告、清空不弹" :closable="false" />
        <el-input
            v-model="infos.alert"
            type="textarea"
            :autosize="true"
            placeholder="请输入公告内容..."
            style="width: 100%;"
        />
        <div style="text-align: center; margin-top: 10px;">
            <el-button type="primary" @click="confirmSave('alert', infos.alert)">保存</el-button>
            <el-button type="primary" @click="confirmClear('alert')">清空</el-button>
        </div>

        <el-divider />
        <el-alert type="warning" center description="账号列表上方公告" :closable="false" />
        <el-input
            v-model="infos.notice"
            type="textarea"
            :autosize="true"
            placeholder="请输入公告内容..."
            style="width: 100%;"
        />
        <div style="text-align: center; margin-top: 10px;">
            <el-button type="primary" @click="confirmSave('notice', infos.notice)">保存</el-button>
            <el-button type="primary" @click="confirmClear('notice')">清空</el-button>
        </div>

        <el-divider />
        <el-alert type="warning" center description="账号列表下方公告" :closable="false" />
        <el-input
            v-model="infos.usage"
            type="textarea"
            :autosize="true"
            placeholder="请输入公告内容..."
            style="width: 100%;"
        />
        <div style="text-align: center; margin-top: 10px;">
            <el-button type="primary" @click="confirmSave('usage', infos.usage)">保存</el-button>
            <el-button type="primary" @click="confirmClear('usage')">清空</el-button>
        </div>

        <el-divider />
        <el-alert type="warning" center description="购买页上方公告" :closable="false" />
        <el-input
            v-model="infos.buy"
            type="textarea"
            :autosize="true"
            placeholder="请输入公告内容..."
            style="width: 100%;"
        />
        <div style="text-align: center; margin-top: 10px;">
            <el-button type="primary" @click="confirmSave('buy', infos.buy)">保存</el-button>
            <el-button type="primary" @click="confirmClear('buy')">清空</el-button>
        </div>

        <el-divider />
        <el-alert type="warning" center description="个人中心上方公告" :closable="false" />
        <el-input
            v-model="infos.me"
            type="textarea"
            :autosize="true"
            placeholder="请输入公告内容..."
            style="width: 100%;"
        />
        <div style="text-align: center; margin-top: 10px;">
            <el-button type="primary" @click="confirmSave('me', infos.me)">保存</el-button>
            <el-button type="primary" @click="confirmClear('me')">清空</el-button>
        </div>
    </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { adminJwt } from './store.js'

export default {
    setup() {
        const infos = ref({ alert: '', notice: '', usage: '', buy: '', me: '' })

        onMounted(() => {
            fetchInfos()
        })

        const fetchInfos = async () => {
            try {
                const response = await axios.get('/job/info', {})
                const data = response.data
                Object.keys(data).forEach(key => {
                    if (data[key] === null) {
                        data[key] = ''
                    }
                })
                infos.value = data
            } catch (error) {
                console.log(error)
            }
        }

        const save = (keyy, value) => {
            axios.post('/job/backend/editInfo', { 'key': keyy, 'value': value }, {
                headers: {
                    'Authorization': `Bearer ${adminJwt.value}`
                }
            })
                .then(response => {
                    ElMessage.success("保存成功")
                })
                .catch(error => {
                    ElMessage.error("保存失败")
                })
        }

        const clear = (keyy) => {
            axios.post('/job/backend/editInfo', { 'key': keyy }, {
                headers: {
                    'Authorization': `Bearer ${adminJwt.value}`
                }
            })
                .then(response => {
                    infos.value[keyy] = ''
                    ElMessage.success("清空成功")
                })
                .catch(error => {
                    ElMessage.error("清空失败")
                })
        }

        const confirmSave = (keyy, value) => {
            ElMessageBox.confirm('确定要保存当前内容吗？', '确认保存', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                save(keyy, value)
            }).catch(() => {
                ElMessage.info('已取消保存')
            })
        }

        const confirmClear = (keyy) => {
            ElMessageBox.confirm('确定要清空当前内容吗？', '确认清空', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                clear(keyy)
            }).catch(() => {
                ElMessage.info('已取消清空')
            })
        }

        return {
            infos,
            confirmSave,
            confirmClear
        }
    }
}
</script>
