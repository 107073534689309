import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import { autoLogin, isLoggedIn } from '@/components/frontend/store';

// Import components
import List from '@/components/frontend/List.vue';
import Mall from '@/components/frontend/Mall.vue';
import Me from '@/components/frontend/Me.vue';
import Partner from '@/components/frontend/Partner.vue';


import AdminLogin from '@/components/backend/AdminLogin.vue';
import AccountManage from '@/components/backend/AccountManage.vue';
import UserTokenNotUsedManage from '@/components/backend/UserTokenNotUsedManage.vue';
import TradeManage from '@/components/backend/TradeManage.vue';
import ChatgptSessionManage from '@/components/backend/ChatgptSessionManage.vue';
import ClaudeSessionManage from '@/components/backend/ClaudeSessionManage.vue';
import NoticeManage from '@/components/backend/NoticeManage.vue';
import FileManage from '@/components/backend/FileManage.vue';
import ItemManage from '@/components/backend/ItemManage.vue';
import JobConfigManage from '@/components/backend/JobConfigManage.vue';
import { ElMessage } from 'element-plus';

// Define routes
const routes = [
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/mall',
    name: 'Mall',
    component: Mall
  },
  {
    path: '/me',
    name: 'Me',
    component: Me
  },
  {
    path: '/partner',
    name: 'Partner',
    component: Partner
  },
  {
    // 跳到admin，如果登录过了就
    path: '/myadmin',
    name: 'AdminLogin',
    component: AdminLogin
  },
  {
    path: '/myadmin/accountManage',
    name: 'AccountManage',
    component: AccountManage
  },
  {
    path: '/myadmin/userTokenNotUsedManage',
    name: 'UserTokenNotUsedManage',
    component: UserTokenNotUsedManage
  },
  {
    path: '/myadmin/tradeManage',
    name: 'TradeManage',
    component: TradeManage
  },
  {
    path: '/myadmin/chatgptSessionManage',
    name: 'ChatgptSessionManage',
    component: ChatgptSessionManage
  },
  {
    path: '/myadmin/claudeSessionManage',
    name: 'ClaudeSessionManage',
    component: ClaudeSessionManage
  },
  {
    path: '/myadmin/noticeManage',
    name: 'NoticeManage',
    component: NoticeManage
  },
  {
    path: '/myadmin/fileManage',
    name: 'FileManage',
    component: FileManage
  },
  {
    path: '/myadmin/itemManage',
    name: 'ItemManage',
    component: ItemManage
  },
  {
    path: '/myadmin/jobConfigManage',
    name: 'JobConfigManage',
    component: JobConfigManage
  },
];

// Create router
const router = createRouter({
  // 开发环境用hash，打包用history
  history: process.env.NODE_ENV === 'development' ? createWebHashHistory() : createWebHistory(),
  routes
});

// Add a global beforeEach guard
router.beforeEach(async (to, from, next) => {
  await autoLogin(); // 执行自动登录逻辑

  if (to.name === 'Me' && !isLoggedIn.value) {
    if (!from.name) {
      // 直接访问 /me 时且未登录，跳转到 /

      next('/list');
    } else {
      // 导航访问时未登录，不跳转
      ElMessage({
        message: '请先登录',
        grouping: true,
        type: 'error',
      });
      next(false);
    }
  } else {
    next();
  }
});

export default router;
