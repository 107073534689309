<template>
    <div class="file-manager">
      <!-- 文件上传组件 -->
      <el-upload
        class="upload-demo"
        :http-request="uploadWithAuth"
        list-type="text"
        :file-list="fileList"
        :show-file-list="false"
      >
        <el-button type="primary">点击上传</el-button>
      </el-upload>
  
      <!-- 文件列表展示 -->
      <el-table :data="fileList" style="width: 100%; margin-top: 20px">
        <!-- 预览图标列 -->
        <el-table-column label="预览" width="100">
          <template v-slot="scope">
            <div v-if="isImage(scope.row.url)">
              <el-image
                :src="scope.row.url"
                fit="cover"
                style="width: 50px; height: 50px; border-radius: 5px"
                lazy
              ></el-image>
            </div>
            <div v-if="isVideo(scope.row.url)">
              <el-icon>
                <i class="el-icon-video-camera-solid"></i>
              </el-icon>
            </div>
          </template>
        </el-table-column>
  
        <!-- 文件名 -->
        <el-table-column prop="name" label="文件名"></el-table-column>
  
        <!-- 操作列 -->
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="text" @click="previewFile(scope.row)">预览</el-button>
            <el-button type="danger" @click="deleteFile(scope.row)">删除</el-button>
            <el-button type="primary" @click="copyPath(scope.row.url)">复制路径</el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <!-- 预览弹窗 -->
      <el-dialog v-model="previewDialogVisible" width="50%">
        <template #title>
          <span>文件预览</span>
        </template>
        <div v-if="isImage(previewFileUrl)">
          <img :src="previewFileUrl" style="width: 100%" />
        </div>
        <div v-if="isVideo(previewFileUrl)">
          <video controls style="width: 100%">
            <source :src="previewFileUrl" type="video/mp4" />
            您的浏览器不支持视频播放。
          </video>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script setup>
  import axios from 'axios';
  import { adminJwt } from './store.js'; // 从 store.js 引入 jwt
  import { ref, onMounted } from 'vue';
  import { ElMessage, ElMessageBox } from 'element-plus';
  
  // 文件列表和预览弹窗的状态
  const fileList = ref([]);
  const previewDialogVisible = ref(false);
  const previewFileUrl = ref('');
  
  // 上传文件并自动添加 Authorization header
  const uploadWithAuth = async (options) => {
    const formData = new FormData();
    formData.append('file', options.file);
  
    try {
      const response = await axios.post('/job/backend/files/upload', formData, {
        headers: {
          Authorization: `Bearer ${adminJwt.value}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.data.url) {
        ElMessage.success('上传成功');
        fetchFiles(); // 重新获取文件列表
        options.onSuccess(response.data); // 通知上传组件成功
      } else {
        ElMessage.error('上传失败');
        options.onError(new Error('上传失败'));
      }
    } catch (error) {
      ElMessage.error('上传失败');
      options.onError(error); // 通知上传组件失败
    }
  };
  
  // 判断是否为图片文件
  const isImage = (url) => {
    return url.match(/\.(jpeg|jpg|gif|png)$/i);
  };
  
  // 判断是否为视频文件
  const isVideo = (url) => {
    return url.match(/\.(mp4|webm|ogg)$/i);
  };
  
  // 预览文件
  const previewFile = (file) => {
    previewFileUrl.value = file.url;
    previewDialogVisible.value = true;
  };
  
  // 删除文件
  const deleteFile = (file) => {
    ElMessageBox.confirm(`确定要删除文件 ${file.name} 吗？`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      axios
        .delete(`/job/backend/files/delete/${file.name}`, {
          headers: {
            Authorization: `Bearer ${adminJwt.value}`,
          },
        })
        .then(() => {
          ElMessage.success('删除成功');
          fetchFiles(); // 重新获取文件列表
        })
        .catch((error) => {
          ElMessage.error('删除失败');
          console.error(error);
        });
      });
  };
  
  // 复制文件路径
  const copyPath = (path) => {
    const relativePath = path; // 文件相对路径
    navigator.clipboard.writeText(relativePath).then(() => {
      ElMessage.success('文件路径复制成功');
    }).catch((err) => {
      ElMessage.error('复制失败');
      console.error('复制失败', err);
    });
  };
  
  // 获取文件列表
  const fetchFiles = () => {
    axios
      .get('/job/backend/files/getNameList', {
        headers: {
          Authorization: `Bearer ${adminJwt.value}`,
        },
      })
      .then((response) => {
        fileList.value = response.data.files.map((file) => ({
          name: file.name,
          url: `/job/${file.name}`,
        }));
      })
      .catch((error) => {
        ElMessage.error('获取文件列表失败');
        console.error(error);
      });
  };
  
  // 在组件挂载时获取文件列表
  onMounted(() => {
    fetchFiles();
  });
  </script>
  
  <style scoped>
  .upload-demo {
    margin-bottom: 20px;
  }
  
  .el-icon {
    font-size: 24px;
    color: #409EFF;
  }
  </style>
  