<template>
    <div class="table-container">
        <!-- 搜索框 -->
        <div class="search-container">
            <el-input v-model="searchKeyword" placeholder="Search..." @input="handleSearch" clearable
                class="search-input" />
        </div>

        <!-- 表格 -->
        <el-table :data="trades" stripe border style="width: 100%" @sort-change="handleSortChange">
            <el-table-column prop="tradeNo" label="Trade No" width="180" sortable="custom"></el-table-column>
            <el-table-column prop="itemName" label="Item Name" width="180" sortable="custom"></el-table-column>
            <el-table-column prop="isPlus" label="Is Plus" width="100" align="center"
                sortable="custom"></el-table-column>
            <el-table-column prop="duration" label="Duration" width="100" align="center"
                sortable="custom"></el-table-column>
            <el-table-column prop="price" label="Price" width="100" align="center" sortable="custom"></el-table-column>
            <el-table-column prop="payTime" label="Pay Time" width="180" sortable="custom"></el-table-column>
            <el-table-column prop="accountId" label="Account ID" width="120" align="center"
                sortable="custom"></el-table-column>
            <el-table-column prop="userNotUsedId" label="User Not Used ID" width="180"
                sortable="custom"></el-table-column>
            <el-table-column prop="couponId" label="Coupon ID" width="120" align="center"
                sortable="custom"></el-table-column>

            <!-- 操作列 -->
            <el-table-column label="操作" fixed="right" width="140" align="center">
                <template #default="scope">
                    <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="pagination-container">
            <div class="total">Total: {{ total }}</div>
            <el-pagination background layout="sizes, prev, pager, next" :total="total" :page-size="pageSize"
                :page-sizes="[10, 50, 100, 200, 500]" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" class="pagination" small>
            </el-pagination>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from 'axios'
import { adminJwt } from './store.js'

// 响应式数据
const trades = ref([])      // 存储表格数据
const total = ref(0)          // 总记录数
const currentPage = ref(1)    // 当前页码
const pageSize = ref(10)      // 每页显示的记录数，初始值为10
const searchKeyword = ref('') // 搜索关键字

// 初始化时获取第一页的数据
onMounted(async () => {
    await fetchTrades(currentPage.value, pageSize.value, searchKeyword.value)
})

// 获取Trade数据的函数
const fetchTrades = async (page, size, keyword = '', sortBy = 'tradeNo', sortDesc = false) => {
    try {
        const response = await axios.post('/job/backend/trade/search', {
            page,
            size,
            keyword,
            sortBy,
            sortDesc
        }, {
            headers: {
                'Authorization': `Bearer ${adminJwt.value}`
            }
        })

        if (response.data.status === 200) {
            trades.value = response.data.data
            total.value = response.data.total
        } else {
            ElMessage.error('Failed to fetch trades')
        }
    } catch (error) {
        ElMessage.error('Error fetching trades')
        console.error(error)
    }
}

// 处理搜索输入
const handleSearch = async () => {
    if (searchKeyword.value.length >= 3 || searchKeyword.value.length === 0) {
        currentPage.value = 1
        await fetchTrades(currentPage.value, pageSize.value, searchKeyword.value)
    }
}

// 处理排序变化
const handleSortChange = async ({ prop, order }) => {
    const sortDesc = order === 'descending'
    await fetchTrades(currentPage.value, pageSize.value, searchKeyword.value, prop, sortDesc)
}

// 处理页码变化
const handleCurrentChange = async (newPage) => {
    currentPage.value = newPage
    await fetchTrades(newPage, pageSize.value, searchKeyword.value)
}

// 处理每页显示数量的变化
const handleSizeChange = async (newSize) => {
    pageSize.value = newSize
    await fetchTrades(currentPage.value, newSize, searchKeyword.value)
}

// 点击删除按钮
const handleDelete = async (trade) => {
    try {
        await ElMessageBox.confirm('此操作将永久删除该交易记录, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        })

        const response = await axios.post('/job/backend/trade/delete', { tradeNo: trade.tradeNo }, {
            headers: {
                'Authorization': `Bearer ${adminJwt.value}`
            }
        })

        if (response.data.status === 200) {
            ElMessage.success('Trade deleted successfully')
            await fetchTrades(currentPage.value, pageSize.value, searchKeyword.value)
        } else {
            ElMessage.error(response.data.message)
        }
    } catch (error) {
        if (error !== 'cancel') {
            ElMessage.error('Error deleting trade')
            console.error(error)
        }
    }
}
</script>

<style scoped>
.table-container {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.search-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-input {
    width: 300px;
}

.el-table {
    border: 1px solid #ebeef5;
    border-radius: 4px;
}

.el-table th {
    background-color: #f5f7fa;
    color: #606266;
    font-weight: bold;
}

.el-table td {
    color: #606266;
}

.pagination-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* 控制子元素之间的间距 */
}

.pagination-container>* {
    display: block;
}

.total {
    font-size: 14px;
    color: #606266;
}

.pagination {
    display: flex;
    justify-content: flex-start;
}
</style>