<template>
  <div class="stats-container">
    <el-alert center type="success">
      收益计算公式：主站价格比例% × 主站最终价+差额比例% × (本站最终价-主站最终价)
      <br>
      当前主站价格比例(%)：{{ principalRate }}%
      <br>
      当前差额比例：{{ differenceRate }}%
      <br>
      如果主站价格上涨，这两个比例会相应上涨，不会暗扣你的利润
      <br>
      举例1：假设主站月卡30元，本站设置价格倍数为1.0(即本站月卡也为30元)。当你卖出一张月卡时，获得的收益为：{{ principalRate }}% x 30 + {{ differenceRate }}% x (30 -
      30) = {{ principalRate / 100.0 * 30 + differenceRate / 100.0 * 0 }}元
      <br>
      举例2：假设主站月卡30元，本站设置价格倍数为2.0(即本站月卡60元)。当你卖出一张月卡时，获得的收益为：{{ principalRate }}% x 30 + {{ differenceRate }}% x (60 -
      30) = {{ principalRate / 100.0 * 30 + differenceRate / 100.0 * 30 }}元
      <br>
      举例3：假设主站月卡30元，本站设置价格倍数为2.0(即本站月卡60元)。如果用户使用了9折优惠券进行购买(实付54元)，你的收益为：{{ principalRate }}% x 27 + {{ differenceRate
      }}% x (54 - 27) = {{ principalRate / 100.0 * 27 + differenceRate / 100.0 * 27 }}元
      <br><br>
      结款时间：每周日
    </el-alert>

    <!-- 注册人数 -->
    <el-card shadow="hover" class="stat-card">
      <div class="stat-header">
        <el-text>总注册人数</el-text>
      </div>
      <div class="stat-value">{{ registerCount }}</div>
    </el-card>

    <!-- 总收益 -->
    <el-card shadow="hover" class="stat-card">
      <div class="stat-header">
        <el-text>总收益</el-text>
      </div>
      <div class="stat-value">{{ totalEarned }}</div>
    </el-card>

    <!-- 待结款 -->
    <el-card shadow="hover" class="stat-card">
      <div class="stat-header">
        <el-text>待结款</el-text>
      </div>
      <div class="stat-value">{{ restEarned }}</div>
    </el-card>
  </div>

  <!-- 交易记录表格 -->
  <el-card shadow="hover" class="trade-card">
    <div class="table-header">
      <el-text>交易记录(最近50条)</el-text>
    </div>
    <el-table :data="trades" style="width: 100%" show-overflow-tooltip>
      <el-table-column prop="tradeNo" label="交易号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="itemName" label="商品名" show-overflow-tooltip></el-table-column>
      <el-table-column prop="originPrice" label="主站原价" show-overflow-tooltip></el-table-column>
      <el-table-column prop="price" label="主站折后价" show-overflow-tooltip></el-table-column>
      <el-table-column prop="payTime" label="支付时间" show-overflow-tooltip width="170"></el-table-column>
      <el-table-column prop="priceMultiple" label="本站价格倍数" show-overflow-tooltip></el-table-column>
      <el-table-column prop="partnerOriginPrice" label="本站原价" show-overflow-tooltip></el-table-column>
      <el-table-column prop="partnerPrice" label="本站折后价" show-overflow-tooltip></el-table-column>
      <el-table-column prop="principalRate" label="主站价格比例(%)" show-overflow-tooltip></el-table-column>
      <el-table-column prop="differenceRate" label="差额比例(%)" show-overflow-tooltip></el-table-column>
      <el-table-column prop="principalProfit" label="主站最终价收益" show-overflow-tooltip></el-table-column>
      <el-table-column prop="differenceProfit" label="差价收益" show-overflow-tooltip></el-table-column>
      <el-table-column prop="totalProfit" label="总收益" show-overflow-tooltip></el-table-column>
    </el-table>
  </el-card>

  <!-- 配置项编辑 -->
  <el-card shadow="hover" class="config-card">
    <div class="config-header">
      <el-text>配置信息</el-text>
    </div>

    <!-- 文件管理按钮 -->
    <el-button type="primary" @click="openFileManager">文件管理</el-button>

    <!-- 文件管理的 Drawer -->
    <el-drawer v-model="fileManagerVisible" title="文件管理" size="50%">
      <el-upload
        class="upload-demo"
        drag
        action=""
        :http-request="uploadFile"
        :on-success="handleUploadSuccess"
        :on-error="handleUploadError"
        :before-upload="beforeUpload"
        :multiple="false"
        accept="image/*"
        :show-file-list="false"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传图片文件</div>
      </el-upload>

      <el-table :data="files" style="width: 100%; margin-top: 20px">
        <el-table-column prop="name" label="文件名"></el-table-column>
        <el-table-column label="操作" width="300">
          <template #default="scope">
            <!-- 预览按钮 -->
            <el-button type="primary" @click="previewFile(scope.row.name)">预览</el-button>
            <!-- 复制路径按钮 -->
            <el-button type="primary" @click="copyFilePath(scope.row.name)">复制路径</el-button>
            <!-- 删除按钮 -->
            <el-button type="danger" @click="confirmDelete(scope.row.name)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>

    <!-- 图片预览弹窗 -->
    <el-dialog v-model="previewVisible" title="文件预览" width="50%">
      <img :src="previewImage" style="width: 100%" alt="预览图片" />
    </el-dialog>

    <el-form label-position="top" class="config-form">
      <!-- domain 显示但不可编辑 -->
      <el-form-item label="域名">
        <el-input v-model="domain" disabled></el-input>
      </el-form-item>

      <!-- 支付二维码 -->
      <el-form-item label="支付宝二维码链接(如/job/xxx.jpg)">
        <el-input v-model="alipayQrCode" placeholder="文件管理中把二维码上传，然后复制路径，粘贴到这"></el-input>
      </el-form-item>

      <!-- price_multiple 只能输入数字 -->
      <el-form-item label="价格倍率(你想卖的价格为主站的多少倍，最低为1)">
        <el-input v-model.number="priceMultiple" type="number"></el-input>
      </el-form-item>

      <!-- contact -->
      <el-form-item label="联系方式(必填，不然出问题找不到你人)">
        <el-input v-model="contact"></el-input>
      </el-form-item>

      <el-alert center type="error" :closable="false">
        1. 下面为公告设置，可使用html标签以及element plus标签，新手建议只改联系方式，想显示群二维码等图片的，把显示交流群图片的el-image标签中的src属性改成你的图片链接。
        <br>
        2. 不懂又想自定义的，去问gpt。
        <br>
        3. 公告改完一定要仔细检查！并前往账号列表、在线商城、个人中心进行验证！语法错误可能会导致用户界面显示不出来！
        <br>
        <el-link href="https://jsrun.net/56VKp" target="_blank">html、element plus在线调试网站</el-link>
      </el-alert>

      <!-- info_map 中的各个字段 -->
      <el-form-item label="弹窗公告，为空不会弹">
        <el-input :autosize="true" v-model="infoMap.alert" type="textarea" rows="2"></el-input>
      </el-form-item>

      <el-form-item label="账号列表上方的公告">
        <el-input :autosize="true" v-model="infoMap.notice" type="textarea" rows="2"></el-input>
      </el-form-item>

      <el-form-item label="账号列表下方的公告">
        <el-input :autosize="true" v-model="infoMap.usage" type="textarea" rows="3"></el-input>
      </el-form-item>

      <el-form-item label="在线商城上方的公告">
        <el-input :autosize="true" v-model="infoMap.buy" type="textarea" rows="4"></el-input>
      </el-form-item>

      <el-form-item label="个人中心上方的公告">
        <el-input :autosize="true" v-model="infoMap.me" type="textarea" rows="2"></el-input>
      </el-form-item>

      <!-- 保存按钮 -->
      <el-button type="primary" @click="saveConfig">保存配置</el-button>
    </el-form>
  </el-card>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus';
import axios from 'axios';
import { isLoggedIn } from './store';

const router = useRouter();

const domain = ref('');
const priceMultiple = ref(1);
const contact = ref('');
const infoMap = ref({
  alert: '',
  notice: '',
  usage: '',
  buy: '',
  me: ''
});
const alipayQrCode = ref('');

const principalRate = ref(0);
const differenceRate = ref(0);
const registerCount = ref(0);
const totalEarned = ref(0);
const restEarned = ref(0);
const trades = ref([]);

const fileManagerVisible = ref(false); // 控制Drawer显示
const files = ref([]); // 文件列表

const previewVisible = ref(false); // 控制预览弹窗的显示
const previewImage = ref(''); // 存储当前预览的图片链接

// 打开文件管理器
const openFileManager = () => {
  fileManagerVisible.value = true;
  fetchFileList();
};

// 获取文件列表
const fetchFileList = () => {
  const loginToken = localStorage.getItem('loginToken');
  axios
    .get('/job/partner/files/getNameList', {
      params: {
        loginToken,
      },
    })
    .then((response) => {
      files.value = response.data.files;
    })
    .catch((error) => {
      console.error('获取文件列表失败:', error);
    });
};

// 上传文件前检查
const beforeUpload = (file) => {
  const isImage = file.type.startsWith('image/');
  if (!isImage) {
    ElMessage.error('只能上传图片文件');
  }
  return isImage;
};

// 上传文件
const uploadFile = (options) => {
  const loginToken = localStorage.getItem('loginToken');
  const formData = new FormData();
  formData.append('file', options.file);
  formData.append('loginToken', loginToken);

  axios
    .post('/job/partner/files/upload', formData)
    .then((response) => {
      if(response.data.status === '200'){
        ElMessage.success('文件上传成功');
        fetchFileList();
      }else{
        ElMessage.error(response.data.error);
      }
    })
    .catch((error) => {
      ElMessage.error('文件上传失败');
      console.error('文件上传失败:', error);
    });
};

// 文件上传成功处理
const handleUploadSuccess = () => {
  ElMessage.success('文件上传成功');
  fetchFileList();
};

// 文件上传失败处理
const handleUploadError = () => {
  ElMessage.error('文件上传失败');
};

// 删除文件时询问确认
const confirmDelete = (fileName) => {
  ElMessageBox.confirm(
    '此操作将永久删除该文件, 是否继续?',
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    deleteFile(fileName);
  }).catch(() => {
    ElMessage.info('已取消删除');
  });
};

// 删除文件
const deleteFile = (fileName) => {
  const loginToken = localStorage.getItem('loginToken');
  axios
    .delete(`/job/partner/files/delete/${fileName}`, {
      params: {
        loginToken,
      },
    })
    .then((response) => {
      ElMessage.success('文件删除成功');
      fetchFileList();
    })
    .catch((error) => {
      ElMessage.error('文件删除失败');
      console.error('文件删除失败:', error);
    });
};

// 预览文件
const previewFile = (fileName) => {
  previewImage.value = `/job/${fileName}`;
  previewVisible.value = true;
};

// 复制文件路径
const copyFilePath = (fileName) => {
  const fileUrl = `/job/${fileName}`;
  navigator.clipboard.writeText(fileUrl).then(() => {
    ElMessage.success('路径复制成功');
  }).catch(() => {
    ElMessage.error('路径复制失败');
  });
};

onMounted(() => {
  if (!isLoggedIn.value) {
    ElMessage({
      message: '请先登录',
      type: 'error',
    });
    router.push('/list');
  } else {
    const loginToken = localStorage.getItem('loginToken');

    axios
      .post('/job/partner/getPartner', {
        loginToken: loginToken,
      })
      .then((response) => {
        const data = response.data;

        // 保存配置信息
        domain.value = data.domain;
        priceMultiple.value = data.config.price_multiple || 1;
        contact.value = data.config.contact || '';
        infoMap.value = data.config.info_map || {
          alert: '',
          notice: '',
          usage: '',
          buy: '',
          me: ''
        };
        alipayQrCode.value = data.config.alipay_qr_code || '';

        // 其他数据
        principalRate.value = data.principalRate || 0;
        differenceRate.value = data.differenceRate || 0;
        registerCount.value = data.registerCount || 0;
        totalEarned.value = data.totalEarned || 0;
        restEarned.value = data.restEarned || 0;
        trades.value = data.trades || [];
      })
      .catch((error) => {
        console.error('接口请求出错:', error);
      });
  }
});

const saveConfig = () => {
  const loginToken = localStorage.getItem('loginToken');
  const configData = {
    price_multiple: priceMultiple.value,
    contact: contact.value,
    info_map: infoMap.value,
    alipay_qr_code: alipayQrCode.value,
  };

  axios
    .post('/job/partner/updateConfig', {
      loginToken: loginToken,
      config: JSON.stringify(configData),
    })
    .then((response) => {
      if (response.data.status === 200) {
        ElMessage({
          message: '配置保存成功',
          type: 'success',
        });
      } else {
        ElMessage({
          message: response.data.message,
          type: 'error',
        });
      }
    })
    .catch((error) => {
      console.error('保存配置出错:', error);
      ElMessage({
        message: '保存配置失败',
        type: 'error',
      });
    });
};
</script>

<style scoped>
.stats-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px 0;
}

.stat-card {
  width: 300px;
  margin: 10px;
  text-align: center;
  background-color: #f7f7f7;
}

.stat-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.stat-value {
  font-size: 24px;
  color: #409eff;
  font-weight: bold;
}

.trade-card {
  margin-top: 20px;
}

.table-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.config-card {
  margin-top: 20px;
}

.config-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.config-form {
  margin-top: 20px;
}
</style>
