<template>
    <div class="table-container">
      <!-- 搜索框和新增按钮 -->
      <div class="search-container">
        <el-button type="primary" @click="handleAdd">新增</el-button>
        <el-input v-model="searchKeyword" placeholder="Search..." @input="handleSearch" clearable class="search-input" />
      </div>
  
      <!-- 表格 -->
      <el-table :data="claudeSessions" stripe border style="width: 100%" @sort-change="handleSortChange">
        <el-table-column prop="id" label="ID" width="60" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="email" label="Email" width="180" sortable="custom"></el-table-column>
        <el-table-column prop="password" label="Password" width="150" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="status" label="Status" width="100" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="isPlus" label="Is Plus" width="100" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="carID" label="Car ID" width="150" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="officialSession" label="Official Session" width="180" sortable="custom"></el-table-column>
        <el-table-column prop="remark" label="Remark" width="150" sortable="custom"></el-table-column>
        <el-table-column prop="createTime" label="Create Time" width="180" sortable="custom"></el-table-column>
        <el-table-column prop="updateTime" label="Update Time" width="180" sortable="custom"></el-table-column>
        <el-table-column prop="sort" label="Sort" width="100" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="remaining" label="Remaining" width="100" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="resetsAt" label="Resets At" width="150" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="disable" label="Disable" width="100" align="center" sortable="custom">
          <template #default="scope">
            <el-switch
              v-model="scope.row.disable"
              :active-value="1"
              :inactive-value="0"
              @change="toggleDisable(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
  
        <!-- 操作列 -->
        <el-table-column label="操作" fixed="right" width="140" align="center">
          <template #default="scope">
            <el-button size="small" @click="handleEdit(scope.row)">修改</el-button>
            <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <!-- 分页 -->
      <div class="pagination-container">
        <div class="total">Total: {{ total }}</div>
        <el-pagination background layout="sizes, prev, pager, next" :total="total" :page-size="pageSize"
          :page-sizes="[10, 50, 100, 200, 500]" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          class="pagination" small>
        </el-pagination>
      </div>
  
      <!-- 新增/编辑用户对话框 -->
      <el-dialog v-model="addDialogVisible" title="Claude Session">
        <form autocomplete="off">
          <el-form :model="newClaudeSession" autocomplete="off">
            <el-form-item label="Email" :required="true">
              <el-input v-model="newClaudeSession.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Password" :required="true">
              <el-input v-model="newClaudeSession.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Status">
              <el-input v-model="newClaudeSession.status" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Is Plus">
              <el-input v-model="newClaudeSession.isPlus" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Car ID">
              <el-input v-model="newClaudeSession.carID" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Official Session">
              <el-input v-model="newClaudeSession.officialSession" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Remark">
              <el-input v-model="newClaudeSession.remark" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Create Time">
              <el-input v-model="newClaudeSession.createTime" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Update Time">
              <el-input v-model="newClaudeSession.updateTime" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Sort">
              <el-input v-model="newClaudeSession.sort" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Remaining">
              <el-input v-model="newClaudeSession.remaining" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Resets At">
              <el-input v-model="newClaudeSession.resetsAt" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Disable">
              <el-switch v-model="newClaudeSession.disable" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
          </el-form>
        </form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitNewClaudeSession">保存</el-button>
        </span>
      </el-dialog>
    </div>
  </template>

<script setup>
import { ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from 'axios'
import { adminJwt } from './store.js'

// 响应式数据
const claudeSessions = ref([])      // 存储表格数据
const total = ref(0)          // 总记录数
const currentPage = ref(1)    // 当前页码
const pageSize = ref(10)      // 每页显示的记录数，初始值为10
const searchKeyword = ref('') // 搜索关键字
const addDialogVisible = ref(false) // 控制对话框的显示
const newClaudeSession = ref({ email: '', password: '', status: 0, isPlus: 0, carID: '', officialSession: '', remark: '', createTime: null, updateTime: null, sort: 0, remaining: 0, resetsAt: null, disable: 0 })
const isEditing = ref(false) // 判断是否为编辑模式

// Mapping frontend field names to backend field names
const fieldMappings = {
  id: 'id',
  createTime: 'createTime',  // Use camel case directly as per schema
  updateTime: 'updateTime',  // Use camel case directly as per schema
  deletedAt: 'deleted_at',   // Convert to snake case
  email: 'email',
  password: 'password',
  status: 'status',
  isPlus: 'isPlus',          // Use camel case directly as per schema
  carID: 'carID',            // Special case, directly use as is
  officialSession: 'officialSession', // Use camel case directly as per schema
  remark: 'remark',
  sort: 'sort',
  count: 'count',
  remaining: 'remaining',
  resetsAt: 'resets_at',     // Convert to snake case
  disable: 'disable'         // Updated field
};

// Function to map keys according to the naming convention used in the database
const mapToDatabaseFieldNames = (data) => {
  const result = {};
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const newKey = fieldMappings[key] || key;
      result[newKey] = data[key];
    }
  }
  return result;
};

// 初始化时获取第一页的数据
onMounted(async () => {
  await fetchClaudeSessions(currentPage.value, pageSize.value, searchKeyword.value)
})

// 获取ClaudeSession数据的函数
const fetchClaudeSessions = async (page, size, keyword = '', sortBy = 'id', sortDesc = false) => {
  try {
    const mappedSortBy = fieldMappings[sortBy] || sortBy;

    const response = await axios.post('/job/backend/claudeSession/search', {
      page,
      size,
      keyword,
      sortBy: mappedSortBy,
      sortDesc
    }, {
      headers: {
        'Authorization': `Bearer ${adminJwt.value}`
      }
    });

    if (response.data.status === 200) {
      claudeSessions.value = response.data.data;
      total.value = response.data.total;
    } else {
      ElMessage.error('Failed to fetch Claude sessions');
    }
  } catch (error) {
    ElMessage.error('Error fetching Claude sessions');
    console.error(error);
  }
};

// 处理搜索输入
const handleSearch = async () => {
  if (searchKeyword.value.length >= 3 || searchKeyword.value.length === 0) {
    currentPage.value = 1;
    await fetchClaudeSessions(currentPage.value, pageSize.value, searchKeyword.value);
  }
};

// 处理排序变化
const handleSortChange = async ({ prop, order }) => {
  const sortDesc = order === 'descending';
  await fetchClaudeSessions(currentPage.value, pageSize.value, searchKeyword.value, prop, sortDesc);
};

// 处理页码变化
const handleCurrentChange = async (newPage) => {
  currentPage.value = newPage;
  await fetchClaudeSessions(newPage, pageSize.value, searchKeyword.value);
};

// 处理每页显示数量的变化
const handleSizeChange = async (newSize) => {
  pageSize.value = newSize;
  await fetchClaudeSessions(currentPage.value, newSize, searchKeyword.value);
};

// 点击新增按钮
const handleAdd = () => {
  isEditing.value = false;
  Object.assign(newClaudeSession.value, {
    email: '',
    password: '',
    status: 0,
    isPlus: 0,
    carID: '',
    officialSession: '',
    remark: '',
    createTime: null,
    updateTime: null,
    sort: 0,
    remaining: 0,
    resetsAt: null,
    disable: 0
  });
  addDialogVisible.value = true;
};

// 点击修改按钮
const handleEdit = (claudeSession) => {
  isEditing.value = true;
  Object.assign(newClaudeSession.value, claudeSession);
  addDialogVisible.value = true;
};

// 提交新增或修改用户
const submitNewClaudeSession = async () => {
  try {
    const apiUrl = isEditing.value ? '/job/backend/claudeSession/update' : '/job/backend/claudeSession/add';
    const mappedData = mapToDatabaseFieldNames(newClaudeSession.value);

    if (!isEditing.value) {
      // 新增用户时，删除 id 属性
      delete mappedData.id;
    }

    const response = await axios.post(apiUrl, mappedData, {
      headers: {
        'Authorization': `Bearer ${adminJwt.value}`
      }
    });

    if (response.data.status === 200) {
      ElMessage.success(isEditing.value ? 'Claude session updated successfully' : 'Claude session added successfully');
      addDialogVisible.value = false;
      await fetchClaudeSessions(currentPage.value, pageSize.value, searchKeyword.value);
    } else {
      ElMessage.error(response.data.message);
    }
  } catch (error) {
    ElMessage.error('Error processing Claude session');
    console.error(error);
  }
};

// 点击删除按钮
const handleDelete = async (claudeSession) => {
  try {
    await ElMessageBox.confirm('此操作将永久删除该Claude Session, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    });

    const response = await axios.post('/job/backend/claudeSession/delete', { id: claudeSession.id }, {
      headers: {
        'Authorization': `Bearer ${adminJwt.value}`
      }
    });

    if (response.data.status === 200) {
      ElMessage.success('Claude session deleted successfully');
      await fetchClaudeSessions(currentPage.value, pageSize.value, searchKeyword.value);
    } else {
      ElMessage.error(response.data.message);
    }
  } catch (error) {
    if (error !== 'cancel') {
      ElMessage.error('Error deleting Claude session');
      console.error(error);
    }
  }
};

// Toggle disable state
const toggleDisable = async (claudeSession) => {
  try {
    const response = await axios.post('/job/backend/claudeSession/toggleDisable', {
      id: claudeSession.id,
      disable: claudeSession.disable
    }, {
      headers: {
        'Authorization': `Bearer ${adminJwt.value}`
      }
    });

    if (response.data.status === 200) {
      ElMessage.success('Disable state updated successfully');
      await fetchClaudeSessions(currentPage.value, pageSize.value, searchKeyword.value);
    } else {
      ElMessage.error(response.data.message);
    }
  } catch (error) {
    ElMessage.error('Error toggling disable state');
    console.error(error);
  }
}
</script>

<style scoped>
.table-container {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.search-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input {
  width: 300px;
}

.el-table {
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.el-table th {
  background-color: #f5f7fa;
  color: #606266;
  font-weight: bold;
}

.el-table td {
  color: #606266;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pagination-container>* {
  display: block;
}

.total {
  font-size: 14px;
  color: #606266;
}

.pagination {
  display: flex;
  justify-content: flex-start;
}
</style>
