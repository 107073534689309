<template>
    <el-container style="height: 100vh; width: 100%;">
        <el-row style="height: 100%; width: 100%;">
            <!-- 左侧商品分类区域 -->
            <el-col :span="12" style="height: 100%; overflow-y: auto; border-right: 1px solid #ddd;">
                <el-table :data="categories" style="width: 100%;" border :row-key="row => row.id"
                    @row-click="handleRowClick" v-model:expand-row-keys="expandedRows">
                    <!-- 添加可展开的列 -->
                    <el-table-column type="expand">
                        <template #default="scope">
                            <div style="border: 1px solid #ccc; padding: 10px;">
                                <Toolbar style="border-bottom: 1px solid #ccc; margin-bottom: 10px;"
                                    :editor="scope.row.editorRef" :defaultConfig="toolbarConfig" />
                                <Editor style="height: 300px;" v-model="scope.row.detail" :defaultConfig="editorConfig"
                                    @onCreated="editor => (scope.row.editorRef = editor)" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="ID" width="150" />
                    <el-table-column prop="name" label="分类名称" width="200" />
                    <el-table-column prop="priority" label="优先级" width="150" />
                </el-table>
            </el-col>

            <!-- 右侧商品展示区域 -->
            <el-col :span="12" style="height: 100%; overflow-y: auto;">
                <el-table :data="selectedItems" style="width: 100%" border>
                    <el-table-column prop="id" label="ID" width="60" />
                    <el-table-column prop="name" label="商品名称" width="150" />
                    <el-table-column prop="duration" label="时长（小时）" width="100" />
                    <el-table-column prop="price" label="价格" width="100" />
                    <el-table-column prop="detail" label="详情" show-overflow-tooltip />
                    <el-table-column prop="priority" label="优先级" width="80" />
                    <el-table-column prop="able" label="可用状态" width="80">
                        <template #default="scope">
                            <el-tag type="success" v-if="scope.row.able">可用</el-tag>
                            <el-tag type="danger" v-else>不可用</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </el-container>
</template>

<script>
import { reactive, ref, onMounted, shallowRef, markRaw } from 'vue';
import axios from 'axios';
import '@wangeditor/editor/dist/css/style.css';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';

export default {
    name: 'ItemPage',
    components: { Editor, Toolbar },
    setup() {
        const categories = reactive([]);  // 使用 reactive 而不是 ref
        const selectedItems = ref([]); // 存储当前选择的商品
        const expandedRows = ref([]); // 用于跟踪展开的行
        const mode = 'default';
        const toolbarConfig = {}; // Toolbar 配置项
        const editorConfig = {
            placeholder: '请输入内容...',
        };

        const fetchCategories = async () => {
            try {
                const response = await axios.get('/job/getItems');
                categories.length = 0; // 清空之前的数据
                response.data.forEach(category => {
                    categories.push({
                        ...category,
                        editorRef: markRaw(shallowRef(null)), // 使用 markRaw 包装
                    });
                });
                if (categories.length > 0) {
                    selectedItems.value = categories[0].items; // 默认显示第一个分类的商品
                }
            } catch (error) {
                console.error('Failed to fetch categories:', error);
            }
        };

        const handleRowClick = (row) => {
            selectCategory(row.id);
        };

        const selectCategory = (categoryId) => {
            const category = categories.find(cat => cat.id === categoryId);
            if (category) {
                selectedItems.value = category.items; // 更新右侧商品列表
            }
        };

        onMounted(fetchCategories);

        return {
            categories,
            selectedItems,
            expandedRows, // 将 expandedRows 返回
            mode,
            toolbarConfig,
            editorConfig,
            selectCategory,
            handleRowClick,
        };
    },
};
</script>

<style scoped>
.category-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.category-item h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.category-item div {
    margin-top: 5px;
    color: #666;
    font-size: 14px;
}
</style>
