<template>
  <div class="table-container">
    <!-- 搜索框和新增按钮 -->
    <div class="search-container">
      <el-button type="primary" @click="handleAdd">新增</el-button>
      <el-button type="primary" @click="handleMutipleAdd">批量新增</el-button>
      <el-input v-model="searchKeyword" placeholder="Search..." @input="handleSearch" clearable class="search-input" />
    </div>

    <!-- 表格 -->
    <el-table :data="userTokens" stripe border style="width: 100%" @sort-change="handleSortChange">
      <el-table-column prop="id" label="ID" width="60" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="userToken" label="User Token" width="180" sortable="custom"></el-table-column>
      <el-table-column prop="duration" label="Duration" width="100" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="isPlus" label="Is Plus" width="100" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="isUsed" label="Is Used" width="100" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="isCreateByAdmin" label="Is Created By Admin" width="150" align="center"
        sortable="custom"></el-table-column>
      <el-table-column prop="accountId" label="Account ID" width="120" align="center"
        sortable="custom"></el-table-column>
      <el-table-column prop="createTime" label="Create Time" width="180" sortable="custom"></el-table-column>
      <el-table-column prop="useTime" label="Use Time" width="180" sortable="custom"></el-table-column>
      <el-table-column prop="access" label="Access" width="150" sortable="custom"></el-table-column>

      <!-- 操作列 -->
      <el-table-column label="操作" fixed="right" width="140" align="center">
        <template #default="scope">
          <el-button size="small" @click="handleEdit(scope.row)">修改</el-button>
          <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination-container">
      <div class="total">Total: {{ total }}</div>
      <el-pagination background layout="sizes, prev, pager, next" :total="total" :page-size="pageSize"
        :page-sizes="[10, 50, 100, 200, 500]" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        class="pagination" small>
      </el-pagination>
    </div>

    <!-- 新增/编辑用户对话框 -->
    <el-dialog v-model="addDialogVisible" title="User Token Not Used">
      <form autocomplete="off">
        <el-form :model="newUserToken" autocomplete="off">
          <el-form-item label="User Token" :required="true">
            <el-input v-model="newUserToken.userToken" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Duration" :required="true">
            <el-input v-model="newUserToken.duration" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Is Plus">
            <el-input v-model="newUserToken.isPlus" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Is Used">
            <el-input v-model="newUserToken.isUsed" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Is Created By Admin">
            <el-input v-model="newUserToken.isCreateByAdmin" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Account ID">
            <el-input v-model="newUserToken.accountId" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Create Time">
            <el-input v-model="newUserToken.createTime" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Use Time">
            <el-input v-model="newUserToken.useTime" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Access">
            <el-input v-model="newUserToken.access" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitNewUserToken">保存</el-button>
      </span>
    </el-dialog>

    <!-- 批量新增 -->
    <el-dialog style="width: 100%;" v-model="mutipleAddDialogVisible" title="批量新增">
      <form autocomplete="off">
        <el-form :model="mutipleAddInfo" autocomplete="off">
          <el-form-item label="时长(小时)" :required="true">
            <el-input v-model="mutipleAddInfo.duration" autocomplete="off"></el-input>
            <!-- 添加默认时长按钮组 -->
            <el-button-group style="margin-top: 10px;">
              <el-button @click="setDuration(4)">4小时</el-button>
              <el-button @click="setDuration(24)">1天</el-button>
              <el-button @click="setDuration(168)">7天</el-button>
              <el-button @click="setDuration(720)">1月</el-button>
              <el-button @click="setDuration(1440)">2月</el-button>
              <el-button @click="setDuration(2160)">3月</el-button>
              <el-button @click="setDuration(4320)">6月</el-button>
              <el-button @click="setDuration(8760)">1年</el-button>
            </el-button-group>
          </el-form-item>
          <el-form-item label="isPlus" :required="true">
            <el-input v-model="mutipleAddInfo.isPlus" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="数量">
            <el-input v-model="mutipleAddInfo.amount" autocomplete="off"></el-input>
          </el-form-item>

          <el-input v-model="mutipleAddReturnData" style="" :autosize="{ minRows: 10, maxRows: 40 }" type="textarea"
            placeholder="" />
        </el-form>
      </form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mutipleAddDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitMutipleAdd">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from 'axios'
import { adminJwt } from './store.js'

// 响应式数据
const userTokens = ref([])      // 存储表格数据
const total = ref(0)          // 总记录数
const currentPage = ref(1)    // 当前页码
const pageSize = ref(10)      // 每页显示的记录数，初始值为10
const searchKeyword = ref('') // 搜索关键字
const addDialogVisible = ref(false) // 控制对话框的显示
const mutipleAddDialogVisible = ref(false) // 控制对话框的显示
const newUserToken = ref({ userToken: '', duration: 0, isPlus: 0, isUsed: 0, isCreateByAdmin: 0, accountId: null, createTime: null, useTime: null, access: '' })
const isEditing = ref(false) // 判断是否为编辑模式

const mutipleAddInfo = ref({ duration: '', isPlus: 1, amount: 1 })

const mutipleAddReturnData = ref('')

// 初始化时获取第一页的数据
onMounted(async () => {
  await fetchUserTokens(currentPage.value, pageSize.value, searchKeyword.value)
})

// 获取UserTokenNotUsed数据的函数
const fetchUserTokens = async (page, size, keyword = '', sortBy = 'id', sortDesc = false) => {
  try {
    const response = await axios.post('/job/backend/userTokenNotUsed/search', {
      page,
      size,
      keyword,
      sortBy,
      sortDesc
    }, {
      headers: {
        'Authorization': `Bearer ${adminJwt.value}`
      }
    })

    if (response.data.status === 200) {
      userTokens.value = response.data.data
      total.value = response.data.total
    } else {
      ElMessage.error('Failed to fetch user tokens')
    }
  } catch (error) {
    ElMessage.error('Error fetching user tokens')
    console.error(error)
  }
}

// 处理搜索输入
const handleSearch = async () => {
  if (searchKeyword.value.length >= 3 || searchKeyword.value.length === 0) {
    currentPage.value = 1
    await fetchUserTokens(currentPage.value, pageSize.value, searchKeyword.value)
  }
}

// 处理排序变化
const handleSortChange = async ({ prop, order }) => {
  const sortDesc = order === 'descending'
  await fetchUserTokens(currentPage.value, pageSize.value, searchKeyword.value, prop, sortDesc)
}

// 处理页码变化
const handleCurrentChange = async (newPage) => {
  currentPage.value = newPage
  await fetchUserTokens(newPage, pageSize.value, searchKeyword.value)
}

// 处理每页显示数量的变化
const handleSizeChange = async (newSize) => {
  pageSize.value = newSize
  await fetchUserTokens(currentPage.value, newSize, searchKeyword.value)
}

// 点击新增按钮
const handleAdd = () => {
  isEditing.value = false
  Object.assign(newUserToken.value, {
    userToken: generateUUID(), // 生成随机 UUID 作为默认值
    duration: 0,
    isPlus: 0,
    isUsed: 0,
    isCreateByAdmin: 0,
    accountId: null,
    createTime: null,
    useTime: null,
    access: ''
  })
  addDialogVisible.value = true
}

const handleMutipleAdd = () => {
  mutipleAddDialogVisible.value = true
}

// 生成UUID函数
const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

// 设置时长函数
const setDuration = (hours) => {
  mutipleAddInfo.value.duration = hours;
};

// 点击修改按钮
const handleEdit = (userToken) => {
  isEditing.value = true
  Object.assign(newUserToken.value, userToken)
  addDialogVisible.value = true
}

// 提交新增或修改用户
const submitNewUserToken = async () => {
  try {
    const apiUrl = isEditing.value ? '/job/backend/userTokenNotUsed/update' : '/job/backend/userTokenNotUsed/add';

    if (!isEditing.value) {
      // 新增用户时，删除 id 属性
      delete newUserToken.value.id;
    }

    const response = await axios.post(apiUrl, newUserToken.value, {
      headers: {
        'Authorization': `Bearer ${adminJwt.value}`
      }
    });

    if (response.data.status === 200) {
      ElMessage.success(isEditing.value ? 'User token updated successfully' : 'User token added successfully');
      addDialogVisible.value = false
      await fetchUserTokens(currentPage.value, pageSize.value, searchKeyword.value);
    } else {
      ElMessage.error(response.data.message);
    }
  } catch (error) {
    ElMessage.error('Error processing user token');
    console.error(error);
  }
};

const submitMutipleAdd = async () => {

  await axios.post('/job/backend/userTokenNotUsed/multipleAdd', mutipleAddInfo.value, {
    headers: {
      'Authorization': `Bearer ${adminJwt.value}`
    }
  })
    .then(response => {
      let str = '';
      for (let i = 0; i < response.data.length; i++) {
        str += response.data[i]
        str += '\n'
      }
      mutipleAddReturnData.value = str;
      console.log(mutipleAddReturnData.value)
    })
    .catch(error => {
      console.log(error)
    })
};

// 点击删除按钮
const handleDelete = async (userToken) => {
  try {
    await ElMessageBox.confirm('此操作将永久删除该用户Token, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })

    const response = await axios.post('/job/backend/userTokenNotUsed/delete', { id: userToken.id }, {
      headers: {
        'Authorization': `Bearer ${adminJwt.value}`
      }
    })

    if (response.data.status === 200) {
      ElMessage.success('User token deleted successfully')
      await fetchUserTokens(currentPage.value, pageSize.value, searchKeyword.value)
    } else {
      ElMessage.error(response.data.message)
    }
  } catch (error) {
    if (error !== 'cancel') {
      ElMessage.error('Error deleting user token')
      console.error(error)
    }
  }
}
</script>

<style scoped>
.table-container {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.search-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input {
  width: 300px;
}

.el-table {
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.el-table th {
  background-color: #f5f7fa;
  color: #606266;
  font-weight: bold;
}

.el-table td {
  color: #606266;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* 控制子元素之间的间距 */
}

.pagination-container>* {
  display: block;
}

.total {
  font-size: 14px;
  color: #606266;
}

.pagination {
  display: flex;
  justify-content: flex-start;
}
</style>
