<template>
    <div>
      <!-- 使用 Element Plus 的表单布局 -->
      <el-form :model="configData" label-width="250px" label-position="left" class="config-form">
        <!-- 循环展示配置项 -->
        <el-form-item v-for="(value, key) in configData" :key="key" :label="key">
          <el-input v-model="configData[key]" placeholder="请输入配置值"></el-input>
          <el-button type="primary" @click="updateConfig(key, configData[key])">更新</el-button>
        </el-form-item>
      </el-form>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  import { ElMessage } from 'element-plus'
  import axios from 'axios'
  import { adminJwt } from './store.js'
  
  export default {
    setup() {
      // 初始化对象
      const configData = ref({});
  
      // 加载所有配置数据
      const loadConfigData = async () => {
        try {
          const response = await axios.post('/job/backend/getAllConfig', {}, {
            headers: {
              'Authorization': `Bearer ${adminJwt.value}`
            }
          });
          configData.value = response.data;
        } catch (error) {
          ElMessage.error("加载配置数据失败");
          console.error(error);
        }
      };
  
      // 更新某个配置项
      const updateConfig = async (key, value) => {
        try {
          await axios.post('/job/backend/updateConfig', { key, value }, {
            headers: {
              'Authorization': `Bearer ${adminJwt.value}`
            }
          });
          ElMessage.success(`配置 ${key} 更新成功`);
        } catch (error) {
          ElMessage.error(`配置 ${key} 更新失败`);
          console.error(error);
        }
      };
  
      onMounted(() => {
        loadConfigData(); // 组件加载时请求配置数据
      });
  
      return {
        configData,
        updateConfig
      };
    }
  }
  </script>
  
  <style scoped>
  .config-form {
    width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .el-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .el-input {
    margin-right: 10px;
    flex-grow: 1;
  }
  </style>
  