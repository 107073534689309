<template>
  <div class="table-container">
    <!-- 搜索框和新增按钮 -->
    <div class="search-container">
      <el-button type="primary" @click="handleAdd">新增</el-button>
      <el-button type="success" @click="handleBatchGive">批量发放时长</el-button>
      <el-input v-model="searchKeyword" placeholder="Search..." @input="handleSearch" clearable class="search-input" />
    </div>

    <!-- 表格 -->
    <el-table :data="accounts" stripe border style="width: 100%" @sort-change="handleSortChange">
      <el-table-column prop="id" label="ID" width="60" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="phone" label="Phone" width="180" sortable="custom"></el-table-column>
      <el-table-column prop="password" label="Password" width="200" sortable="custom"></el-table-column>
      <el-table-column prop="inviterId" label="Inviter ID" width="100" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="inviteCode" label="Invite Code" width="150" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="chatgptUserIdPlus" label="ChatGPT Plus" width="150" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="chatgptUserIdNormal" label="ChatGPT Normal" width="150" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="mail" label="Mail" width="250" sortable="custom"></el-table-column>
      <el-table-column prop="loginToken" label="Login Token" width="300" sortable="custom"></el-table-column>
      <el-table-column prop="registerDatetime" label="registerDatetime" width="300" sortable="custom"></el-table-column>

      <!-- 操作列 -->
      <el-table-column label="操作" fixed="right" width="200" align="center">
        <template #default="scope">
          <el-button size="small" @click="handleEdit(scope.row)">修改</el-button>
          <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          <el-button size="small" type="success" @click="handleGive(scope.row)">发放</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination-container">
      <div class="total">Total: {{ total }}</div>
      <el-pagination background layout="sizes, prev, pager, next" :total="total" :page-size="pageSize"
        :page-sizes="[10, 50, 100, 200, 500]" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        class="pagination" small>
      </el-pagination>
    </div>

    <!-- 新增/编辑用户对话框 -->
    <el-dialog v-model="dialogVisible" title="用户信息">
      <form autocomplete="off">
        <el-form :model="newUser" autocomplete="off">
          <el-form-item label="Phone" :required="true">
            <el-input v-model="newUser.phone" autocomplete="off" :name="'phone-' + Math.random()"></el-input>
          </el-form-item>
          <el-form-item label="Password" :required="true">
            <el-input v-model="newUser.password" autocomplete="new-password"></el-input>
          </el-form-item>
          <el-form-item label="Inviter ID">
            <el-input v-model="newUser.inviterId" autocomplete="off" :name="'inviter-' + Math.random()"></el-input>
          </el-form-item>
          <el-form-item label="Invite Code">
            <el-input v-model="newUser.inviteCode" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="ChatGPT Plus User ID">
            <el-input v-model="newUser.chatgptUserIdPlus" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="ChatGPT Normal User ID">
            <el-input v-model="newUser.chatgptUserIdNormal" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Mail">
            <el-input v-model="newUser.mail" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitNewUser">保存</el-button>
      </span>
    </el-dialog>

    <!-- 发放用户Token对话框 -->
    <el-dialog v-model="giveDialogVisible" title="发放用户Token">
      <form autocomplete="off">
        <el-form :model="giveForm" autocomplete="off">
          <el-form-item label="Phone" :required="true">
            <el-input v-model="giveForm.phone" autocomplete="off" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="时长(小时)" :required="true">
            <el-input v-model="giveForm.duration" autocomplete="off"></el-input>
            <!-- 添加默认时长按钮组 -->
            <el-button-group style="margin-top: 10px;">
              <el-button @click="setDuration(4)">4小时</el-button>
              <el-button @click="setDuration(24)">1天</el-button>
              <el-button @click="setDuration(168)">7天</el-button>
              <el-button @click="setDuration(720)">30天</el-button>
              <el-button @click="setDuration(1440)">60天</el-button>
              <el-button @click="setDuration(2160)">90天</el-button>
              <el-button @click="setDuration(4320)">180天</el-button>
              <el-button @click="setDuration(8760)">365天</el-button>
            </el-button-group>
          </el-form-item>
          <el-form-item label="获得方式" :required="true">
            <el-input v-model="giveForm.access" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="giveDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitGiveUserToken">确定</el-button>
      </span>
    </el-dialog>

    <!-- 批量发放Token对话框 -->
    <el-dialog v-model="batchGiveDialogVisible" title="批量发放Token">
      <form autocomplete="off">
        <el-form :model="batchGiveForm" autocomplete="off">
          <el-form-item label="时长(小时)" :required="true">
            <el-input v-model="batchGiveForm.duration" autocomplete="off"></el-input>
            <!-- 添加默认时长按钮组 -->
            <el-button-group style="margin-top: 10px;">
              <el-button @click="setBatchDuration(4)">4小时</el-button>
              <el-button @click="setBatchDuration(24)">1天</el-button>
              <el-button @click="setBatchDuration(168)">7天</el-button>
              <el-button @click="setBatchDuration(720)">30天</el-button>
              <el-button @click="setBatchDuration(1440)">60天</el-button>
              <el-button @click="setBatchDuration(2160)">90天</el-button>
              <el-button @click="setBatchDuration(4320)">180天</el-button>
              <el-button @click="setBatchDuration(8760)">365天</el-button>
            </el-button-group>
          </el-form-item>
          <el-form-item label="获得方式" :required="true">
            <el-input v-model="batchGiveForm.access" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchGiveDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitBatchGiveUserToken">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from 'axios'
import { adminJwt } from './store.js'

// 响应式数据
const accounts = ref([])      // 存储表格数据
const total = ref(0)          // 总记录数
const currentPage = ref(1)    // 当前页码
const pageSize = ref(10)      // 每页显示的记录数，初始值为10
const searchKeyword = ref('') // 搜索关键字
const dialogVisible = ref(false) // 控制对话框的显示
const giveDialogVisible = ref(false) // 控制发放对话框的显示
const batchGiveDialogVisible = ref(false) // 控制批量发放对话框的显示
const newUser = ref({ phone: '', password: '', inviterId: '', inviteCode: '', chatgptUserIdPlus: '', chatgptUserIdNormal: '', mail: '' })
const giveForm = ref({ phone: '', duration: '', access: '系统赠送' }) // 发放表单
const batchGiveForm = ref({ duration: '', access: '系统赠送' }) // 批量发放表单
const isEditing = ref(false) // 判断是否为编辑模式

// 初始化时获取第一页的数据
onMounted(async () => {
  await fetchAccounts(currentPage.value, pageSize.value, searchKeyword.value)
})

// 获取账号数据的函数
const fetchAccounts = async (page, size, keyword = '', sortBy = 'id', sortDesc = false) => {
  try {
    const response = await axios.post('/job/backend/account/search', {
      page,
      size,
      keyword,
      sortBy,
      sortDesc
    }, {
      headers: {
        'Authorization': `Bearer ${adminJwt.value}`
      }
    })

    if (response.data.status === 200) {
      accounts.value = response.data.data
      total.value = response.data.total
    } else {
      ElMessage.error('Failed to fetch accounts')
    }
  } catch (error) {
    ElMessage.error('Error fetching accounts')
    console.error(error)
  }
}

// 响应排序变化
const handleSortChange = async ({ prop, order }) => {
  const sortDesc = order === 'descending'
  await fetchAccounts(currentPage.value, pageSize.value, searchKeyword.value, prop, sortDesc)
}

// 处理搜索输入
const handleSearch = async () => {
  if (searchKeyword.value.length >= 3 || searchKeyword.value.length === 0) {
    currentPage.value = 1
    await fetchAccounts(currentPage.value, pageSize.value, searchKeyword.value)
  }
}

// 处理页码变化
const handleCurrentChange = async (newPage) => {
  currentPage.value = newPage
  await fetchAccounts(newPage, pageSize.value, searchKeyword.value)
}

// 处理每页显示数量的变化
const handleSizeChange = async (newSize) => {
  pageSize.value = newSize
  await fetchAccounts(currentPage.value, newSize, searchKeyword.value)
}

// 点击新增按钮
const handleAdd = () => {
  isEditing.value = false
  Object.assign(newUser.value, { phone: '', password: '', inviterId: '', inviteCode: '', chatgptUserIdPlus: '', chatgptUserIdNormal: '', mail: '' })
  dialogVisible.value = true
}

// 点击修改按钮
const handleEdit = (user) => {
  isEditing.value = true
  Object.assign(newUser.value, user)
  dialogVisible.value = true
}

// 点击发放按钮
const handleGive = (user) => {
  Object.assign(giveForm.value, { phone: user.phone, duration: '', access: '系统赠送' })
  giveDialogVisible.value = true
}

// 点击批量发放时长按钮
const handleBatchGive = () => {
  batchGiveForm.value.duration = ''
  batchGiveDialogVisible.value = true
}

// 设置时长
const setDuration = (hours) => {
  giveForm.value.duration = hours
}

// 设置批量时长
const setBatchDuration = (hours) => {
  batchGiveForm.value.duration = hours
}

// 提交发放用户Token
const submitGiveUserToken = async () => {
  try {
    const response = await axios.post('/job/backend/userTokenNotUsed/giveUserTokenNotUsed', {
      phone: giveForm.value.phone,
      duration: giveForm.value.duration,
      access: giveForm.value.access
    }, {
      headers: {
        'Authorization': `Bearer ${adminJwt.value}`
      }
    })

    if (response.status === 200) {
      ElMessage.success('User token granted successfully')
      giveDialogVisible.value = false
    } else {
      ElMessage.error('Failed to grant user token')
    }
  } catch (error) {
    ElMessage.error('Error granting user token')
    console.error(error)
  }
}

// 提交批量发放用户Token
const submitBatchGiveUserToken = async () => {
  try {
    const response = await axios.post('/job/backend/userTokenNotUsed/giveAllUsersUserTokenNotUsed', {
      duration: batchGiveForm.value.duration,
      access: batchGiveForm.value.access
    }, {
      headers: {
        'Authorization': `Bearer ${adminJwt.value}`
      }
    })

    if (response.status === 200) {
      ElMessage.success('Tokens granted to all users successfully')
      batchGiveDialogVisible.value = false
    } else {
      ElMessage.error('Failed to grant tokens to all users')
    }
  } catch (error) {
    ElMessage.error('Error granting tokens to all users')
    console.error(error)
  }
}

// 提交新增或修改用户
const submitNewUser = async () => {
  try {
    const apiUrl = isEditing.value ? '/job/backend/account/update' : '/job/backend/account/add';

    if (!isEditing.value) {
      // 新增用户时，删除 id 属性
      delete newUser.value.id;
    }

    const response = await axios.post(apiUrl, newUser.value, {
      headers: {
        'Authorization': `Bearer ${adminJwt.value}`
      }
    });

    if (response.data.status === 200) {
      ElMessage.success(isEditing.value ? 'User updated successfully' : 'User added successfully');
      dialogVisible.value = false;
      await fetchAccounts(currentPage.value, pageSize.value, searchKeyword.value);
    } else {
      ElMessage.error(response.data.message);
    }
  } catch (error) {
    ElMessage.error('Error processing user');
    console.error(error);
  }
};

// 点击删除按钮
const handleDelete = async (user) => {
  try {
    await ElMessageBox.confirm('此操作将永久删除该用户, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })

    const response = await axios.post('/job/backend/account/delete', { id: user.id }, {
      headers: {
        'Authorization': `Bearer ${adminJwt.value}`
      }
    })

    if (response.data.status === 200) {
      ElMessage.success('User deleted successfully')
      await fetchAccounts(currentPage.value, pageSize.value, searchKeyword.value)
    } else {
      ElMessage.error(response.data.message)
    }
  } catch (error) {
    if (error !== 'cancel') {
      ElMessage.error('Error deleting user')
      console.error(error)
    }
  }
}
</script>

<style scoped>
.table-container {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.search-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input {
  width: 300px;
}

.el-table {
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.el-table th {
  background-color: #f5f7fa;
  color: #606266;
  font-weight: bold;
}

.el-table td {
  color: #606266;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* 控制子元素之间的间距 */
}

.pagination-container>* {
  display: block;
}

.total {
  font-size: 14px;
  color: #606266;
}

.pagination {
  display: flex;
  justify-content: flex-start;
}
</style>
