<template>
    <div class="table-container">
        <!-- 搜索框 -->
        <div class="search-container">
            <el-input v-model="searchKeyword" placeholder="Search..." @input="handleSearch" clearable
                class="search-input" />
        </div>

        <!-- 表格 -->
        <el-table :data="sessions" stripe border style="width: 100%" @sort-change="handleSortChange">
            <el-table-column prop="id" label="ID" width="60" align="center" sortable="custom"></el-table-column>
            <el-table-column prop="email" label="Email" width="200" sortable="custom"
                show-overflow-tooltip></el-table-column>
            <el-table-column prop="password" label="Password" width="200" sortable="custom"
                show-overflow-tooltip></el-table-column>
            <el-table-column prop="carID" label="Car ID" width="180" sortable="custom"
                show-overflow-tooltip></el-table-column>
            <el-table-column prop="officialSession" label="Official Session" width="200" sortable="custom"
                show-overflow-tooltip></el-table-column>
            <el-table-column prop="remark" label="Remark" width="250" sortable="custom"
                show-overflow-tooltip></el-table-column>
            <el-table-column prop="status" label="Status" width="100" align="center" sortable="custom"></el-table-column>
            <el-table-column prop="isPlus" label="Is Plus" width="100" align="center" sortable="custom"></el-table-column>
            <el-table-column prop="createTime" label="Create Time" width="200" sortable="custom"
                show-overflow-tooltip></el-table-column>
            <el-table-column prop="updateTime" label="Update Time" width="200" sortable="custom"
                show-overflow-tooltip></el-table-column>
            <el-table-column prop="deletedAt" label="Deleted At" width="200" sortable="custom"
                show-overflow-tooltip></el-table-column>
            <el-table-column prop="sort" label="Sort" width="100" align="center" sortable="custom"></el-table-column>
            <el-table-column prop="isDisabled" label="状态" width="100" align="center" sortable="custom">
                <template #default="scope">
                    <el-switch v-model="scope.row.disabled" @change="toggleSession(scope.row)"></el-switch>
                </template>
            </el-table-column>

            <!-- 操作列 -->
            <el-table-column label="操作" fixed="right" width="200" align="center">
                <template #default="scope">
                    <el-button size="small" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="pagination-container">
            <div class="total">Total: {{ total }}</div>
            <el-pagination background layout="sizes, prev, pager, next" :total="total" :page-size="pageSize"
                :page-sizes="[10, 50, 100, 200, 500]" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                class="pagination" small>
            </el-pagination>
        </div>

        <!-- 编辑用户对话框 -->
        <el-dialog v-model="dialogVisible" title="编辑 Session">
            <el-form :model="currentSession" label-width="120px">
                <el-form-item label="Email">
                    <el-input v-model="currentSession.email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Password">
                    <el-input v-model="currentSession.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Car ID">
                    <el-input v-model="currentSession.carID" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Official Session">
                    <el-input v-model="currentSession.officialSession" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Remark">
                    <el-input v-model="currentSession.remark" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Status">
                    <el-switch v-model="currentSession.status"></el-switch>
                </el-form-item>
                <el-form-item label="Is Plus">
                    <el-switch v-model="currentSession.isPlus"></el-switch>
                </el-form-item>
                <el-form-item label="Sort">
                    <el-input v-model="currentSession.sort" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Deleted At">
                    <el-date-picker v-model="currentSession.deletedAt" type="datetime" placeholder="Select date and time"></el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitEdit">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'
import { adminJwt } from './store.js'

// 响应式数据
const sessions = ref([]) // 存储表格数据
const total = ref(0) // 总记录数
const currentPage = ref(1) // 当前页码
const pageSize = ref(10) // 每页显示的记录数，初始值为10
const searchKeyword = ref('') // 搜索关键字

const dialogVisible = ref(false) // 控制对话框的显示
const currentSession = ref({}) // 存储当前编辑的Session数据

// 初始化时获取第一页的数据
onMounted(async () => {
    await fetchSessions(currentPage.value, pageSize.value, searchKeyword.value)
})

// 获取ChatgptSession数据的函数
const fetchSessions = async (page, size, keyword = '', sortBy = 'id', sortDesc = false) => {
    try {
        const response = await axios.post('/job/backend/chatgptSession/search', {
            page,
            size,
            keyword,
            sortBy,
            sortDesc
        }, {
            headers: {
                'Authorization': `Bearer ${adminJwt.value}`
            }
        })

        if (response.data.status === 200) {
            sessions.value = response.data.data
            total.value = response.data.total
        } else {
            ElMessage.error('Failed to fetch sessions')
        }
    } catch (error) {
        ElMessage.error('Error fetching sessions')
        console.error(error)
    }
}

// 点击编辑按钮
const handleEdit = (session) => {
    currentSession.value = { ...session } // 深拷贝当前选中的session数据
    dialogVisible.value = true // 显示编辑对话框
}

// 提交编辑数据
const submitEdit = async () => {
    const sessionData = { ...currentSession.value };

    // 将 deletedAt 转换为 deleted_at
    if ('deletedAt' in sessionData) {
        sessionData.deleted_at = sessionData.deletedAt;
        delete sessionData.deletedAt; // 删除原有的 deletedAt 属性
    }

    // 将 Boolean 转换为 byte (0 or 1)
    sessionData.status = sessionData.status ? 1 : 0;
    sessionData.isPlus = sessionData.isPlus ? 1 : 0;

    try {
        const response = await axios.post('/job/backend/chatgptSession/update', sessionData, {
            headers: {
                'Authorization': `Bearer ${adminJwt.value}`
            }
        });

        if (response.data.status === 200) {
            ElMessage.success('Session updated successfully');
            dialogVisible.value = false;
            await fetchSessions(currentPage.value, pageSize.value, searchKeyword.value); // 更新表格数据
        } else {
            ElMessage.error('Failed to update session');
        }
    } catch (error) {
        ElMessage.error('Error updating session');
        console.error(error);
    }
}


// 删除Session
const handleDelete = (session) => {
    ElMessageBox.confirm(
        `This will delete the session with ID ${session.id}. Do you want to continue?`,
        'Warning',
        {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'warning',
        }
    )
    .then(async () => {
        try {
            const response = await axios.post('/job/backend/chatgptSession/delete', { id: session.id }, {
                headers: {
                    'Authorization': `Bearer ${adminJwt.value}`
                }
            });

            if (response.data.status === 200) {
                ElMessage.success('Session deleted successfully');
                await fetchSessions(currentPage.value, pageSize.value, searchKeyword.value); // 更新表格数据
            } else {
                ElMessage.error('Failed to delete session');
            }
        } catch (error) {
            ElMessage.error('Error deleting session');
            console.error(error);
        }
    })
    .catch(() => {
        ElMessage.info('Delete canceled');
    });
}

// 处理搜索输入
const handleSearch = async () => {
    if (searchKeyword.value.length >= 3 || searchKeyword.value.length === 0) {
        currentPage.value = 1
        await fetchSessions(currentPage.value, pageSize.value, searchKeyword.value)
    }
}

// 处理排序变化
const handleSortChange = async ({ prop, order }) => {
    let sortBy = prop;
    if (prop === 'deletedAt') {
        sortBy = 'deleted_at';
    }
    const sortDesc = order === 'descending';
    await fetchSessions(currentPage.value, pageSize.value, searchKeyword.value, sortBy, sortDesc);
}

// 处理页码变化
const handleCurrentChange = async (newPage) => {
    currentPage.value = newPage
    await fetchSessions(newPage, pageSize.value, searchKeyword.value)
}

// 处理每页显示数量的变化
const handleSizeChange = async (newSize) => {
    pageSize.value = newSize
    await fetchSessions(currentPage.value, newSize, searchKeyword.value)
}

// 切换开关状态
const toggleSession = async (session) => {
    try {
        const response = await axios.post('/job/backend/chatgptSession/toggle', {
            carId: session.id
        }, {
            headers: {
                'Authorization': `Bearer ${adminJwt.value}`
            }
        })

        if (response.data.status === 200) {
            ElMessage.success('Session status toggled successfully')
        } else {
            ElMessage.error('Failed to toggle session status')
        }
    } catch (error) {
        ElMessage.error('Error toggling session status')
        console.error(error)
    }
}
</script>

<style scoped>
.table-container {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.search-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-input {
    width: 300px;
}

.el-table {
    border: 1px solid #ebeef5;
    border-radius: 4px;
}

.el-table th {
    background-color: #f5f7fa;
    color: #606266;
    font-weight: bold;
}

.el-table td {
    color: #606266;
}

.pagination-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pagination-container>* {
    display: block;
}

.total {
    font-size: 14px;
    color: #606266;
}

.pagination {
    display: flex;
    justify-content: flex-start;
}
</style>
